<template>
  <asom-modal
    title="Update Item"
    v-model="showModalComputed"
    :dismissible="false"
  >
    <offline-action-alert v-if="!isOnline" />
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-form-field
      class="col-span-2"
      label="Quantity"
      required
      :state="inputStates('updateItemForm.quantity')"
      error="Quantity is required"
    >
      <asom-input-text
        type="number"
        v-model="updateItemForm.quantity"
        :state="inputStates('updateItemForm.quantity')"
      />
    </asom-form-field>
    <asom-form-field
      class="col-span-2"
      label="Expiry Date"
      :required="inventoryType.requiredExpiredDate"
      :state="inputStates('updateItemForm.expiryDate')"
      error="Expiry Date is required"
    >
      <asom-input-date
        v-model="updateItemForm.expiryDate"
        :state="inputStates('updateItemForm.expiryDate')"
        datepickerPlacement="top-start"
      />
    </asom-form-field>
    <asom-form-field
      required
      label="Remarks"
      :state="inputStates('updateItemForm.remarks')"
      error="Please enter more than 10 characters"
    >
      <asom-input-textarea
        type="text"
        v-model="updateItemForm.remarks"
        :state="inputStates('updateItemForm.remarks')"
        :maxlength="1000"
      />
    </asom-form-field>
    <div class="flex flex-row-reverse pt-6">
      <div class="pl-4">
        <asom-button
          @click="onConfirmation"
          text="OK"
          :loading="isSubmitting"
        />
      </div>
      <div>
        <asom-button
          @click="toggleModal(false)"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </div>
  </asom-modal>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import inputStates from "@/mixins/inputStates";
import {
  required,
  minValue,
  minLength,
  maxLength,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  updateInventory,
  getInventoryTypeDetails,
} from "../../../services/inventory.service";
import OfflineActionAlert from "@/layout/OfflineActionAlert";

export default {
  props: {
    showModal: {
      type: Boolean,
    },
    inventory: {
      type: undefined,
    },
    inventoryGroup: {
      type: Number,
      default: 1,
    },
  },
  components: {
    OfflineActionAlert,
  },
  mixins: [inputStates],
  emits: ["update:showModal", "success", "fail"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      error: null,
      isSubmitting: false,
      updateItemForm: {
        quantity: 1,
        expiryDate: null,
        remarks: null,
      },
      inventoryType: {
        requiredExpiredDate: true,
      },
    };
  },
  validations() {
    let validations = {
      updateItemForm: {
        quantity: { required, minValue: minValue(1) },
        remarks: {
          required,
          maxLength: maxLength(1000),
          minLength: minLength(10),
        },
      },
    };
    if (this.inventoryType.requiredExpiredDate)
      validations.updateItemForm.expiryDate = { required };
    return validations;
  },
  watch: {
    showModal(isShow) {
      if (isShow) {
        this.loadFormData();
      }
    },
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      isOnline: "apiStatus/isInventoryModuleOnline",
    }),
    showModalComputed: {
      get() {
        return this.showModal;
      },
      set(newValue) {
        this.$emit("update:showModal", newValue);
      },
    },
    submissionData() {
      return {
        inventoryId: get(this.inventory, "inventoryId"),
        stationId: this.stationId,
        amount: this.updateItemForm.quantity,
        expiryDate: this.updateItemForm.expiryDate,
        primaryLocationId: get(this.inventory, "primaryLocationId"),
        secondaryLocationName: get(this.inventory, "secondaryLocationName"),
        remarks: this.updateItemForm.remarks,
        inventoryName: get(this.inventory, "inventoryTypeName"),
        inventoryGroup: this.inventoryGroup,
      };
    },
  },
  methods: {
    toggleModal(value) {
      this.$emit("update:showModal", value);
    },
    async getTypeDetails() {
      const result = await getInventoryTypeDetails({
        inventoryTypeId: get(this.inventory, "inventoryTypeId", null),
        stationId: this.stationId,
      });
      if (result.success) {
        let itemDetails = get(
          result.payload,
          "inventoryTypeDetailsViewModel",
          {}
        );
        this.inventoryType.requiredExpiredDate = get(
          itemDetails,
          "requiredExpiredDate",
          true
        );
      }
    },
    loadFormData() {
      this.getTypeDetails();
      this.v$.$reset();
      this.error = null;
      this.isSubmitting = false;
      this.updateItemForm.quantity = get(this.inventory, "amount", 1);
      this.updateItemForm.expiryDate = get(this.inventory, "expiryDate", null);
      this.updateItemForm.remarks = null;
    },
    onConfirmation() {
      this.useItemModalError = null;
      this.v$.updateItemForm.$reset();
      this.v$.updateItemForm.$touch();
      if (!this.v$.updateItemForm.$invalid) {
        if (this.isOnline) {
          this.updateItem();
        } else {
          this.$store.dispatch(
            "offlineData/updateInventory",
            this.submissionData
          );
          this.toggleModal(false);
          this.$emit("success");
        }
      } else {
        this.useItemModalError = "Please complete all required fields";
        this.$emit("fail");
      }
    },
    async updateItem() {
      this.isSubmitting = true;
      const result = await updateInventory(this.submissionData);
      if (result.success) {
        this.isSubmitting = false;
        this.toggleModal(false);
        this.$emit("success");
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$emit("fail");
      }
    },
  },
};
</script>

<style></style>
