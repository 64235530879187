<template>
  <asom-modal
    title="Use Items"
    v-model="showModalComputed"
    :dismissible="false"
  >
    <offline-action-alert v-if="!isOnline" />
    <asom-alert
      variant="error"
      v-if="useItemModalError"
      :error-message="useItemModalError"
    />
    <asom-form-field
      label="Quantity"
      required
      :state="inputStates('useItemForm.quantity')"
      error="Quantity is required"
    >
      <asom-input-text
        type="number"
        v-model="useItemForm.quantity"
        :state="inputStates('useItemForm.quantity')"
      />
    </asom-form-field>
    <p class="pt-4">Are you sure you would like to proceed?</p>
    <div class="flex flex-row-reverse pt-8">
      <div class="pl-4">
        <asom-button
          @click="onConfirmation"
          text="OK"
          :loading="isSubmittingUseItem"
        />
      </div>
      <div>
        <asom-button
          @click="toggleModal(false)"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </div>
  </asom-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import inputStates from "@/mixins/inputStates";
import { required, minValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useInventoryItems } from '../../../services/inventory.service';
import OfflineActionAlert from "@/layout/OfflineActionAlert"

export default {
  props: {
    showModal: {
      type: Boolean,
    },
    inventoryId: {
      type: String,
    },
    inventoryName: {
      type: String,
    },
    inventoryGroup: {
      type: Number,
      default: 1,
    },
  },
  components: {
    OfflineActionAlert
  },
  mixins: [inputStates],
  emits: [
    'update:showModal',
    'success',
    'fail'
  ],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      useItemModalError: null,
      isSubmittingUseItem: false,
      useItemForm: {
        quantity: 1,
      },
    }
  },
  validations() {
    return {
      useItemForm: {
        quantity: {
          required,
          minValue: minValue(1),
        },
      },
    };
  },
  watch: {
    showModal(isShow) {
      if (isShow) {
        this.loadFormData();
      }
    }
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      'isOnline': "apiStatus/isInventoryModuleOnline",
    }),
    showModalComputed: {
      get() {
        return this.showModal;
      },
      set(newValue) {
        this.$emit('update:showModal', newValue);
      }
    },
    submissionData() {
      return {
        inventoryId: this.inventoryId,
        amount: this.useItemForm.quantity,
        stationId: this.stationId,
        remarks: '',
        inventoryName: this.inventoryName,
        inventoryGroup: this.inventoryGroup,
      }
    }
  },
  methods: {
    toggleModal(value) {
      this.$emit('update:showModal', value);
    },
    loadFormData() {
      this.v$.$reset();
      this.useItemModalError = null;
      this.isSubmittingUseItem = false;
      this.useItemForm.quantity = 1;
    },
    onConfirmation() {
      this.useItemModalError = null;
      this.v$.useItemForm.$reset();
      this.v$.useItemForm.$touch();
      if (!this.v$.useItemForm.$invalid) {
        if (this.isOnline) {
          this.useItem();
        } else {
          this.$store.dispatch('offlineData/useInventory', this.submissionData);
          this.toggleModal(false);
          this.$emit('success');
        }
      } else {
        this.useItemModalError = "Please complete all required fields";
        this.$emit('fail');
      }
    },
    async useItem() {
      this.isSubmittingUseItem = true;
      const result = await useInventoryItems(this.submissionData);
      if (result.success) {
        this.isSubmittingUseItem = false;
        this.toggleModal(false);
        this.$emit('success');
      } else {
        this.isSubmittingUseItem = false;
        this.useItemModalError = result.payload;
        this.$emit('fail');
      }
    },
  }
}
</script>

<style>

</style>