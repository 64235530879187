<template>
  <asom-modal title="View QR" v-model="showModalComputed">
    <div class="flex justify-center">
      <img
        alt="qr"
        :src="getBase64Image(qrCodeString)"
        class="shadow-lg rounded max-w-full h-auto align-middle border-none cursor-pointer"
        @click="downloadImage(getBase64Image(qrCodeString), selectedItemName)"
      />
    </div>
  </asom-modal>
</template>

<script>
import {
  getBase64Image,
  downloadImage,
} from "../../../helpers/imageDataHelper";

export default {
  props: {
    showModal: {
      type: Boolean,
    },
    qrCodeString: {
      type: String,
    },
    selectedItemName: {
      type: String,
    },
  },
  emits: ["update:showModal"],
  computed: {
    showModalComputed: {
      get() {
        return this.showModal;
      },
      set(newValue) {
        this.$emit("update:showModal", newValue);
      },
    },
  },
  methods: {
    toggleModal(value) {
      this.$emit("update:showModal", value);
    },
    getBase64Image,
    downloadImage,
  },
};
</script>

<style></style>
