import { InventoryTransactionTypes } from "../constants/APIEnums/inventoryEnums";

export const activityLogTranasctionAmount = (amount, transactionTypeEnum) => {
  if (transactionTypeEnum == InventoryTransactionTypes.MOVE_IN.VALUE) {
    return "+" + amount;
  } else if (transactionTypeEnum == InventoryTransactionTypes.USE.VALUE) {
    return "-" + amount;
  } else if (
    transactionTypeEnum == InventoryTransactionTypes.UPDATE.VALUE &&
    amount > 0
  ) {
    return "+" + amount;
  } else if (
    transactionTypeEnum == InventoryTransactionTypes.VERIFY_FIRST_AID.VALUE
  ) {
    return "NA";
  } else return amount;
};
