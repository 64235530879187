import get from "lodash.get";
import { InventoryGroups } from "../../../constants/APIEnums/inventoryEnums";

export const checkScanResults = {
  methods: {
    checkPage(itemDetails, inventoryGroupEnum) {
      if (get(itemDetails, "inventoryGroupEnum") == inventoryGroupEnum) {
        return get(itemDetails, "inventoryTypeName", null);
      } else if (
        get(itemDetails, "inventoryGroupEnum") == InventoryGroups.GENERAL.VALUE
      ) {
        this.$router.push({
          name: "General Inventory",
          params: itemDetails,
        });
      } else if (
        get(itemDetails, "inventoryGroupEnum") ==
        InventoryGroups.FIRST_AID.VALUE
      ) {
        this.$router.push({
          name: "First Aid",
          params: itemDetails,
        });
      } else if (
        get(itemDetails, "inventoryGroupEnum") ==
        InventoryGroups.SERIALISED_TAG.VALUE
      ) {
        this.$router.push({
          name: "Tracked Items Inventory",
          params: itemDetails,
        });
      } else if (
        get(itemDetails, "inventoryGroupEnum") ==
        InventoryGroups.SMART_CARD.VALUE
      ) {
        this.$router.push({
          name: "Smart Card Inventory",
          params: itemDetails,
        });
      }
    },
  },
};
